export const formatStringForTooltip = value => {
  /*
      data from api: 4349.4685
      data after format: 4.349,46 €
    */

  if (value == 0) return '0 €'

  let splitedString = ('' + value).split('.')
  let endString = ''
  endString =
    splitedString[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function($1) {
      return $1 + '.'
    }) + ','

  if (splitedString[1] == undefined) return endString.slice(0, -1) + ' €'

  if (splitedString[1].length > 2) {
    endString = endString + splitedString[1][0] + splitedString[1][1] + ' €'
  } else {
    endString = endString + splitedString[1] + ' €'
  }

  return endString
}
