import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "mx-auto my-12 v-card v-sheet theme--light"
  }, [_c(VTabs, {
    attrs: {
      "grow": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c(VTab, {
    attrs: {
      "href": "#IncomingOrders"
    }
  }, [_vm._v("Auftragseingänge")]), _c(VTab, {
    attrs: {
      "href": "#MonthlyComparisons"
    }
  }, [_vm._v("Monatsansicht")]), _c(VTab, {
    attrs: {
      "href": "#OrderValuePerCustomer"
    }
  }, [_vm._v("Auftragswert / Kunde")]), _c(VTab, {
    attrs: {
      "href": "#NumberOfOrdersAndCustomers"
    }
  }, [_vm._v("Auftragsanzahl, Kundenanzahl")]), _c(VTab, {
    attrs: {
      "href": "#Forecast"
    }
  }, [_vm._v("Forecast")])], 1), _c(VTabsItems, {
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c(VTabItem, {
    attrs: {
      "value": "IncomingOrders"
    }
  }, [_c('IncomingOrders')], 1), _c(VTabItem, {
    attrs: {
      "value": "MonthlyComparisons"
    }
  }, [_c('MonthlyComparisons')], 1), _c(VTabItem, {
    attrs: {
      "value": "OrderValuePerCustomer"
    }
  }, [_c('OrderValuePerCustomer')], 1), _c(VTabItem, {
    attrs: {
      "value": "NumberOfOrdersAndCustomers"
    }
  }, [_c('NumberOfOrdersAndCustomers')], 1), _c(VTabItem, {
    attrs: {
      "value": "Forecast"
    }
  }, [_c('Forecast')], 1)], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };