export default {
  props: {
    tabName: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    dataTableKey: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      search: '',
      singleSelect: true,
      selected: []
    };
  }
};