import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "mx-5"
  }, [_c('h2', {
    staticClass: "mb-3"
  }, [_vm._v("Adressart Auswählen")]), _c(VSelect, {
    staticClass: "mb-5 mt-3",
    attrs: {
      "items": _vm.addressTypesItems,
      "item-text": "name",
      "item-value": "id",
      "label": "Adressart"
    },
    on: {
      "input": _vm.showFiveYears
    },
    model: {
      value: _vm.selectedAddressType,
      callback: function ($$v) {
        _vm.selectedAddressType = $$v;
      },
      expression: "selectedAddressType"
    }
  }), _vm.selectedAddressType != '' ? _c('div', [_c('fiveYearGraph', {
    attrs: {
      "highestYearInFiveShownYears": _vm.highestYearInFiveShownYears,
      "fiveYearDataArray": _vm.fiveYearDataArray,
      "headLineText": _vm.headLineText,
      "entireCustomerInFiveYears": _vm.entireCustomerInFiveYears,
      "activeCustomer": _vm.activeCustomer,
      "inactiveCustomer": _vm.inactiveCustomer
    },
    on: {
      "increaseOneYearInFiveYearChart": _vm.increaseOneYearInFiveYearChart,
      "decreaseOneYearInFiveYearChart": _vm.decreaseOneYearInFiveYearChart,
      "showMonth": _vm.showMonth
    }
  })], 1) : _c('div', {
    staticClass: "text-center mx-auto my-8 py-3 text--disabled",
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v(" Wählen Sie eine Adressart ")]), _vm.currentYear != 0 ? _c('div', [_c('monthGraph', {
    attrs: {
      "currentYear": _vm.currentYear,
      "monthlyData": _vm.newCustomerPerMonth,
      "entireCustomerData": _vm.entireCustomerData,
      "activeCustomer": _vm.activeCustomerOfMonth,
      "inactiveCustomer": _vm.inactiveCustomerOfMonth
    },
    on: {
      "increaseOneYear": _vm.increaseOneYear,
      "decreaseOneYear": _vm.decreaseOneYear
    }
  })], 1) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };