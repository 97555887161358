import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [[_c(VCardTitle, [_vm._v(" Artikeltyp auswählen ")]), _c(VCardText, [_c(VTextField, {
    staticClass: "shrink mb-3",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.articleTypesSearch,
      callback: function ($$v) {
        _vm.articleTypesSearch = $$v;
      },
      expression: "articleTypesSearch"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.articleTypesHeaders,
      "items": _vm.articleTypesArray,
      "single-select": _vm.singleSelect,
      "search": _vm.articleTypesSearch,
      "items-per-page": 10,
      "item-key": "name",
      "show-select": ""
    },
    on: {
      "input": _vm.selectArticleType
    },
    model: {
      value: _vm.articleTypesSelected,
      callback: function ($$v) {
        _vm.articleTypesSelected = $$v;
      },
      expression: "articleTypesSelected"
    }
  })], 1)], _vm.articleTypesSelected.length != 0 ? _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', {
    staticClass: "ml-5 my-8"
  }, [_vm._v(" Artikeltypentwicklung "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes - 4) + "-" + _vm._s(this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseFiveYears
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseFiveYears
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('apexchart', {
    ref: "fiveYearChartsOfArticleTypes",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.fiveYearChartOptionsOfArticleTypes,
      "series": _vm.fiveYearChartsOfArticleTypesSeries
    }
  }), _vm.selectedYear != 0 ? _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v(" Monatsansicht "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.selectedYear))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseOneYear
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseOneYear
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('apexchart', {
    ref: "oneYearChartsOfArticleTypes",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.oneYearChartOptionsOfArticleTypes,
      "series": _vm.oneYearChartsOfArticleTypesSeries
    }
  })], 1) : _vm._e(), _c(VRow, {
    staticClass: "mt-8 mx-2"
  }, [_c(VCol, {
    staticClass: "col-md-4"
  }, [_c(VTextField, {
    staticClass: "mb-3",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Kategoriesuche",
      "single-line": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.categorySearch,
      callback: function ($$v) {
        _vm.categorySearch = $$v;
      },
      expression: "categorySearch"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.articleCategoryHeaders,
      "items": _vm.articleCategoryArray,
      "single-select": _vm.singleSelect,
      "items-per-page": 10,
      "search": _vm.categorySearch,
      "item-key": "name",
      "show-select": ""
    },
    on: {
      "input": _vm.showArticlesInCategory
    },
    model: {
      value: _vm.articleCategorySelected,
      callback: function ($$v) {
        _vm.articleCategorySelected = $$v;
      },
      expression: "articleCategorySelected"
    }
  })], 1), _c(VCol, [_c(VTextField, {
    staticClass: "mb-3",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Artikelsuche",
      "single-line": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.articleSearch,
      callback: function ($$v) {
        _vm.articleSearch = $$v;
      },
      expression: "articleSearch"
    }
  }), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.articleOfCategoryHeaders,
      "items": _vm.articleOfCategoryArray,
      "items-per-page": 10,
      "search": _vm.articleSearch,
      "item-key": "artNr"
    },
    scopedSlots: _vm._u([{
      key: "item.symbol",
      fn: function ({
        value
      }) {
        return [_c('OpenLinkEyeComponent', {
          attrs: {
            "destination": value,
            "newTab": true
          }
        })];
      }
    }], null, false, 1702945862),
    model: {
      value: _vm.articleOfCategorySelected,
      callback: function ($$v) {
        _vm.articleOfCategorySelected = $$v;
      },
      expression: "articleOfCategorySelected"
    }
  })], 1)], 1), this.articleCategorySelected.length != 0 ? _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v(" Entwicklung der Artikelkategorie "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory - 4) + "-" + _vm._s(this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseFiveYearsOfCategory
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseFiveYearsOfCategory
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('apexchart', {
    ref: "fiveYearChartsOfArticleCategory",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.fiveYearChartOptionsOfArticleCategory,
      "series": _vm.fiveYearChartsOfArticleCategorySeries
    }
  })], 1) : _vm._e(), _vm.selectedYearOfCategory != 0 ? _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v(" Monatsansicht "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.selectedYearOfCategory))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseOneYearOfCategory
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseOneYearOfCategory
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('apexchart', {
    ref: "oneYearChartsOfArticleCategory",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.oneYearChartOptionsOfArticleCategory,
      "series": _vm.oneYearChartsOfArticleCategorySeries
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };