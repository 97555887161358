import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "mx-auto my-12 v-card v-sheet theme--light"
  }, [_c(VTabs, {
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c(VTab, {
    attrs: {
      "href": "#total"
    }
  }, [_vm._v("Gesamt")]), _c(VTab, {
    attrs: {
      "href": "#stores"
    }
  }, [_vm._v("Filialen")]), _c(VTab, {
    attrs: {
      "href": "#addressTypes"
    }
  }, [_vm._v("Adressarten")]), _c(VTab, {
    attrs: {
      "href": "#customersGroups"
    }
  }, [_vm._v("Kundengruppen")])], 1), _c(VTabsItems, {
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c(VTabItem, {
    attrs: {
      "value": "total"
    }
  }, [_c('totalDevelopment')], 1), _c(VTabItem, {
    attrs: {
      "value": "stores"
    }
  }, [_c('storeDevelopment')], 1), _c(VTabItem, {
    attrs: {
      "value": "addressTypes"
    }
  }, [_c('addressTypes')], 1), _c(VTabItem, {
    attrs: {
      "value": "customersGroups"
    }
  }, [_c('customersGroups')], 1)], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };