import "core-js/modules/es.array.push.js";
import statisticsGroupsService from '@/services/statistics/statisticsGroupsService.js';
import dataTable from '@/components/statistics/articles/dataTable.vue';
import articleTable from '@/components/statistics/articles/articleTable.vue';
import FiveYearGraph from './fiveYearGraph.vue';
import FormatService from '@/services/formatters/currencyFormatService.js';
import { monthNames } from '@/services/statistics/chartService';
const REVENUE_TYPES = ['net', 'db', 'netTotal', 'dbTotal'];
export default {
  components: {
    dataTable,
    articleTable,
    FiveYearGraph
  },
  data: function () {
    return {
      tabName: 'Statistikgruppe',
      headLineText: 'Entwicklung der Statistikgruppen',
      dataTableKey: 'group',
      statisticGroupsSearch: '',
      articlesInStatisticGroupSearch: '',
      statisticGroupsSelected: [],
      statisticGroupsObjectArray: [],
      statisticGroupsArray: [],
      singleSelect: true,
      highestYearInFiveShownYearsOfSatisticGroups: new Date().getFullYear(),
      selectedYear: 0,
      dbYear: [],
      revenueYear: [],
      dbSum: [],
      posNettoSum: [],
      statisticGroupsHeaders: [{
        text: 'Statistikgruppe',
        value: 'group',
        align: 'start',
        sortable: true
      }],
      articlesInStatisticGroupHeaders: [{
        text: 'Artikel',
        value: 'articlename',
        align: 'start',
        sortable: true
      }, {
        text: 'Artikelnummer',
        value: 'artNr',
        align: 'start',
        sortable: true
      }],
      articlesInStatisticGroupArray: [],
      articlesInStatisticGroupObjectArray: [],
      ///////////////////////////
      // Five Year Chart //
      fiveYearChartsOfStatisticGroupsSeries: [],
      fiveYearChartOptionsOfStatisticGroups: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.showMonth(this.highestYearInFiveShownYearsOfSatisticGroups - 4 + config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [new Date().getFullYear() - 4, new Date().getFullYear() - 3, new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()]
        },
        yaxis: [{
          /*
          data from api: 4349.4685
          data after format: 4.000 €
          */
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
                return $1 + '.';
              }) + ' €';
            }
          }
        }],
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        }
      },
      ////////////////////
      // One Year Chart //
      oneYearChartsOfStatisticGroupsSeries: [],
      oneYearChartOptionsOfStatisticGroups: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: monthNames
        },
        yaxis: [{
          /*
          data from api: 4349.4685
          data after format: 4.000 €
          */
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
                return $1 + '.';
              }) + ' €';
            }
          }
        }],
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        }
      }
      //////////////////////////
    };
  },
  methods: {
    shwoStatistikGroups() {
      statisticsGroupsService.getListOfAllArticleGroups().then(response => {
        this.statisticGroupsObjectArray = response.data;
        this.statisticGroupsObjectArray.forEach(element => {
          this.statisticGroupsArray.push({
            group: element.bezeichnung,
            code: element.code
          });
        });
      });
    },
    showArticlesInStatisticGroup(selected) {
      this.statisticGroupsSelected = selected;
      this.selectedYear = 0;
      console.log(this.statisticGroupsSelected[0].code);
      statisticsGroupsService.getListOfArticlesInGroup(this.statisticGroupsSelected[0].code).then(response => {
        this.articlesInStatisticGroupObjectArray = response.data;
        this.articlesInStatisticGroupArray = [];
        this.articlesInStatisticGroupObjectArray.forEach(element => {
          this.articlesInStatisticGroupArray.push({
            articlename: element.artBezeichung,
            artNr: element.artNr
          });
        });
      });
      this.showFiveYearsOfArticleGroups();
    },
    showFiveYearsOfArticleGroups() {
      let responseDataArray = [];
      this.dbSum = [];
      this.posNettoSum = [];
      this.revenueYear = [];
      this.dbYear = [];
      console.log(this.statisticGroupsSelected[0].code, this.highestYearInFiveShownYearsOfSatisticGroups - 4);
      statisticsGroupsService.getFiveYearDataOfStatisticGroup(this.statisticGroupsSelected[0].code, this.highestYearInFiveShownYearsOfSatisticGroups - 4).then(response => {
        responseDataArray = response.data;
        for (let i = 0; i < responseDataArray.length; i++) {
          this.dbSum.push(responseDataArray[i].dbSum);
          this.posNettoSum.push(responseDataArray[i].posNettoSum);
          this.revenueYear.push(responseDataArray[i].revenueYear);
          this.dbYear.push(responseDataArray[i].dbYear);
        }
      });
    },
    showMonth(year) {
      console.log(year);
      this.selectedYear = year;
      let dbSum = [];
      let posNettoSum = [];
      let dbYear = [];
      let revenueMonth = [];
      let responseDataArray = [];
      statisticsGroupsService.getOneYearDataOfStatisticGroup(this.statisticGroupsSelected[0].code, year).then(response => {
        responseDataArray = response.data;
        responseDataArray.forEach(element => {
          dbSum.push(element.dbSum);
          posNettoSum.push(element.posNettoSum);
          revenueMonth.push(element.revenueMonth);
          dbYear.push(element.dbMonth);
        });
        this.$refs.oneYearChartsOfStatisticGroups.updateSeries([{
          name: 'Umsatz',
          data: revenueMonth,
          type: 'line'
        }, {
          name: 'Deckungsbeitrag',
          data: dbYear,
          type: 'line'
        }]);
      });
    },
    increaseOneYear() {
      this.selectedYear--;
      this.showMonth(this.selectedYear);
    },
    decreaseOneYear() {
      if (this.selectedYear < new Date().getFullYear()) {
        this.selectedYear++;
        this.showMonth(this.selectedYear);
      }
    },
    increaseFiveYears() {
      this.highestYearInFiveShownYearsOfSatisticGroups--;
      this.showFiveYearsOfArticleGroups();
    },
    decreaseFiveYears() {
      if (this.highestYearInFiveShownYearsOfSatisticGroups < new Date().getFullYear()) {
        this.highestYearInFiveShownYearsOfSatisticGroups++;
        this.showFiveYearsOfArticleGroups();
      }
    }
  },
  beforeMount() {
    this.shwoStatistikGroups();
  }
};