import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';

// Services
import addressService from '@/services/crm/addressService.js';
import RevenueService from '@/services/statistics/revenues.js';

// Components
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import TimeFilter from '@/components/statistics/TimeFilter.vue';
export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    result: {},
    articleRevenue: [],
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    headers: [{
      text: 'Kategorie',
      value: 'description'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }]
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    articleType: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data;
      });
      this.loadArticleCategoriesStatistics();
    },
    loadArticleCategoriesStatistics() {
      this.loading = true;
      RevenueService.getCustomerArticleTypes2(this.lfdnr, this.articleType, this.filterFromDate, this.filterToDate).then(response => {
        this.articleRevenue = response.data;
        this.loading = false;
      });
    },
    openArticleCategory(item) {
      // statisticsDistributorArticleCategories
      this.$router.push({
        name: 'statisticsDistributorArticles',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: {
          lfdnr: this.lfdnr,
          articleType: this.articleType,
          category: item.nr
        }
      });
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    })
  },
  mounted() {
    this.init();
  },
  watch: {
    filterFromDate: function () {
      this.loadArticleCategoriesStatistics();
    },
    filterToDate: function () {
      this.loadArticleCategoriesStatistics();
    }
  }
};