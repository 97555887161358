import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('SiteHeaderComponent', {
    attrs: {
      "header": _vm.address.name
    }
  }), _c('div', {
    staticClass: "text-center grey--text text--darken-2 mb-12"
  }, [_c('h3', [_c('router-link', {
    attrs: {
      "to": {
        name: 'statisticsDistributorArticleTypes',
        params: {
          lfdnr: _vm.lfdnr
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.result.typeName) + " ")]), _vm._v(" > Artikelkategorien ")], 1)]), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    staticClass: "rounded-pill",
    attrs: {
      "color": "primary",
      "to": {
        name: 'statisticsArticleSearch',
        params: {
          lfdnr: _vm.lfdnr
        },
        query: this.$route.query
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v(" mdi-magnify ")]), _vm._v(" Artikelsuche ")], 1)], 1), _c('div', [_c('TimeFilter')], 1), _c(VCard, {
    staticClass: "mx-auto mb-12"
  }, [_c(VCardTitle, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VDataTable, {
    staticClass: "elevation-1 datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.result.categories,
      "items-per-page": 30,
      "search": _vm.search,
      "item-key": "code",
      "loading": _vm.loading,
      "sort-by": "description",
      "sort-desc": _vm.sortDesc,
      "mobile-breakpoint": "800"
    },
    on: {
      "click:row": _vm.openArticleCategory
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Artikelkategorien in diesem Zeitraum vorhanden. ")];
      },
      proxy: true
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };