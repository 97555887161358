import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "mx-3"
  }, [_c('dataTable', {
    attrs: {
      "tabName": _vm.tabName,
      "headers": _vm.statisticGroupsHeaders,
      "items": _vm.statisticGroupsArray,
      "dataTableKey": _vm.dataTableKey
    },
    on: {
      "show": _vm.showArticlesInStatisticGroup
    }
  }), this.statisticGroupsSelected.length != 0 ? _c('articleTable', {
    attrs: {
      "items": _vm.articlesInStatisticGroupArray,
      "headers": _vm.articlesInStatisticGroupHeaders
    }
  }) : _vm._e(), this.statisticGroupsSelected.length != 0 ? _c('div', {
    staticClass: "mt-10"
  }, [_c('fiveYearGraph', {
    attrs: {
      "highestYearInFiveShownYears": _vm.highestYearInFiveShownYearsOfSatisticGroups,
      "headLineText": _vm.headLineText,
      "dbYear": _vm.dbYear,
      "revenueYear": _vm.revenueYear,
      "dbSum": _vm.dbSum,
      "posNettoSum": _vm.posNettoSum
    },
    on: {
      "increaseFiveYears": _vm.increaseFiveYears,
      "decreaseFiveYears": _vm.decreaseFiveYears,
      "showMonth": _vm.showMonth
    }
  })], 1) : _vm._e(), _vm.selectedYear != 0 ? _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v(" Monatsansicht "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.selectedYear))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseOneYear
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseOneYear
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('apexchart', {
    ref: "oneYearChartsOfStatisticGroups",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.oneYearChartOptionsOfStatisticGroups,
      "series": _vm.oneYearChartsOfStatisticGroupsSeries
    }
  })], 1) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };