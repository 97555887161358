import "core-js/modules/es.array.push.js";
import articleTypes from '@/components/statistics/articles/articleTypes.vue';
import statisticGroups from '@/components/statistics/articles/statisticGroups.vue';
import statisticCodes from '@/components/statistics/articles/statisticCodes.vue';
import aufwandskonten from '@/components/statistics/articles/aufwandskonten.vue';
import fibukonten from '@/components/statistics/articles/fibukonten.vue';
//import expenseGroups from '@/components/statistics/Revenue/RevenueGroups.vue'
import RevenueService from '@/services/statistics/revenueService.js';
import { mapGetters } from 'vuex';
export default {
  components: {
    articleTypes,
    statisticGroups,
    statisticCodes,
    aufwandskonten,
    fibukonten
    // expenseGroups
  },
  data: () => ({
    currentTab: ''
  }),
  methods: {
    init() {
      this.$store.dispatch('setCurrentTab', 'revenuegroups');
      // getting all available shops and saving them into an array to display them
      RevenueService.getFilterOptions('/company/locations', true).then(res => {
        this.$store.dispatch('setShops', res.data);
      });

      // Initially getting the minimum year through revenueTime.js service and saving minYear to $store
      RevenueService.getMinimumYearForRevenue().then(res => {
        let currentYear = new Date().getFullYear();
        this.$store.dispatch('setYearStart', currentYear - 2); // setting yearStart to year to current Year -2 (for example: 2022 (currentYear) - 2 = 2020 (yearStart))
        this.$store.dispatch('setYearEnd', currentYear); // setting yearEnd to current Year
        this.$store.dispatch('setMinYear', res.data);

        // filling items: Array with year-numbers (minYear - currentYear) and saving it to YearItems
        let items = [];
        for (let i = this.getMinYear; i <= currentYear; i++) {
          items.push(i);
        }
        this.$store.dispatch('setYearItems', items);
        this.$store.dispatch('setYearShow', true);
      });
    }
  },
  computed: {
    ...mapGetters(['getYearStart', 'getYearEnd', 'getMinYear', 'getYearItems', 'getSelectedYear', 'getYearShow'])
  },
  watch: {
    currentTab() {
      const tab = this.currentTab;
      if (this.$route.query.tab != tab) this.$router.replace({
        query: {
          ...this.$route.query,
          tab
        }
      });
    }
  },
  mounted() {
    this.currentTab = this.$route.query.tab;
    this.init();
  }
};