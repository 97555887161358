import IncomingOrders from '@/components/statistics/offer/IncomingOrders.vue';
import MonthlyComparisons from '@/components/statistics/offer/MonthlyComparisons.vue';
import OrderValuePerCustomer from '@/components/statistics/offer/OrderValuePerCustomer.vue';
import NumberOfOrdersAndCustomers from '@/components/statistics/offer/NumberOfOrdersAndCustomers.vue';
import Forecast from '@/components/statistics/offer/Forecast.vue';
export default {
  components: {
    IncomingOrders,
    MonthlyComparisons,
    OrderValuePerCustomer,
    NumberOfOrdersAndCustomers,
    Forecast
  },
  data: () => ({
    currentTab: ''
  }),
  watch: {
    currentTab() {
      const tab = this.currentTab;
      if (this.$route.query.tab != tab) this.$router.replace({
        query: {
          ...this.$route.query,
          tab
        }
      });
      this.$store.dispatch('setOfferCurrentTab', tab);
      this.$store.dispatch('setOfferSelectedYear', -1);
      this.$store.dispatch('setOfferMonthly', false);
      this.$store.dispatch('setLoading', false);
    }
  },
  mounted() {
    this.currentTab = this.$route.query.tab;
    this.init();
  }
};