import axios from 'axios'

export default {
  getListOfAllArticleGroups() {
    return axios.get(`/v1/statistics/articles/groups`)
  },
  getListOfArticlesInGroup(id) {
    return axios.get(`/v1/statistics/articles/groups/${id}/articles`)
  },
  getFiveYearDataOfStatisticGroup(id, startyear) {
    return axios.get(`/v1/statistics/articles/groups/${id}/articles/years`, {
      params: { year: startyear }
    })
  },
  getOneYearDataOfStatisticGroup(id, month) {
    return axios.get(`/v1/statistics/articles/groups/${id}/articles/months`, {
      params: { year: month }
    })
  }
}
