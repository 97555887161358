import "core-js/modules/es.array.push.js";
import OfferService from '@/services/statistics/offerService.js';
import MixinMethodObject from '@/components/statistics/offer/IncomingOrders.vue';
import FormatService from '@/services/formatters/currencyFormatService.js';
import RevenueService from '@/services/statistics/revenueService.js';
import { mapGetters } from 'vuex';
const MONTHS = 12;
export default {
  mixins: [MixinMethodObject],
  data: () => ({
    monthChartIncomingObjectArray: [],
    monthChartOfferValuePerCustomerObjectArray: [],
    monthChartAverageOfferCustomerObjectArray: [],
    monthChartForecastObjectArray: [],
    monthChartOptions: {
      title: {
        align: 'left',
        style: {
          fontSize: '20px'
        }
      },
      chart: {
        zoom: {
          enabled: false
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right'
      },
      markers: {
        enabled: true,
        size: 5
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: ['Jän', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        title: {
          text: 'Monat'
        }
      },
      yaxis: [{
        title: {
          text: 'Anzahl'
        }
      }]
    }
  }),
  methods: {
    updateOrdersOfYearSpan() {
      this.$store.dispatch('setLoading', true);
      let monthMonthObjectArray = this.monthMonthObjectArray = [];
      OfferService.getMonthlyRevenueOfYear('', this.getOfferYearStart, this.getOfferYearEnd).then(res => {
        for (let month = 1; month <= MONTHS; month++) {
          monthMonthObjectArray.push(month);
        }
        let incomeOfferArray = [];
        let tempOfferArray = [];
        res.data.forEach(offerObject => {
          tempOfferArray.push(offerObject.orders);
          if (offerObject.month == 12) {
            incomeOfferArray.push({
              name: offerObject.year,
              data: tempOfferArray
            });
            tempOfferArray = [];
          }
        });
        this.$refs.monthChartIncoming.updateSeries(incomeOfferArray);
        this.$refs.monthChartIncoming.updateOptions({
          title: {
            text: 'Auftragseingänge'
          },
          yaxis: {
            min: min => {
              if (incomeOfferArray.some(items => items.data.some(x => x < 0))) {
                return min;
              } else {
                return 0;
              }
            },
            forceNiceScale: true,
            decimalsInFloat: 0
          }
        });
        OfferService.getMonthlyRevenueOfYear('/offerPerCustomer', this.getOfferYearStart, this.getOfferYearEnd).then(res => {
          this.$refs.monthChartAverageOfferCustomer1.updateSeries(res.data[0]);
          this.$refs.monthChartAverageOfferCustomer1.updateOptions({
            title: {
              text: 'Ø Auftragsanzahl, Kundenanzahl'
            },
            yaxis: [{
              min: min => {
                if (res.data[0].some(items => items.data.some(x => x < 0))) {
                  return min;
                } else {
                  return 0;
                }
              },
              forceNiceScale: true,
              decimalsInFloat: 0,
              title: {
                text: 'Durchschnitt'
              }
            }, {
              min: min => {
                if (res.data[0].some(items => items.data.some(x => x < 0))) {
                  return min;
                } else {
                  return 0;
                }
              },
              forceNiceScale: true,
              decimalsInFloat: 0,
              opposite: true,
              title: {
                text: 'Anzahl'
              }
            }]
          });
          this.$refs.monthChartAverageOfferCustomer2.updateSeries(res.data[1]);
          this.$refs.monthChartAverageOfferCustomer2.updateOptions({
            title: {
              text: 'Ø Gesamte Auftragsanzahl, Gesamte Kundenazahl'
            },
            yaxis: [{
              min: min => {
                if (res.data[1].some(items => items.data.some(x => x < 0))) {
                  return min;
                } else {
                  return 0;
                }
              },
              forceNiceScale: true,
              decimalsInFloat: 0,
              title: {
                text: 'Durchschnitt'
              }
            }, {
              min: min => {
                if (res.data[1].some(items => items.data.some(x => x < 0))) {
                  return min;
                } else {
                  return 0;
                }
              },
              forceNiceScale: true,
              decimalsInFloat: 0,
              opposite: true,
              title: {
                text: 'Anzahl'
              }
            }]
          });
          OfferService.getMonthlyRevenueOfYear('/forecast', this.getOfferYearStart, this.getOfferYearEnd).then(res => {
            let dashedArray = [];
            res.data[1].forEach(() => {
              dashedArray.push(5);
            });
            this.$refs.monthChartForecast1.updateSeries(res.data[0]);
            this.$refs.monthChartForecast1.updateOptions({
              yaxis: {
                min: min => {
                  if (res.data[0].some(items => items.data.some(x => x < 0))) {
                    return min;
                  } else {
                    return 0;
                  }
                },
                forceNiceScale: true,
                title: {
                  text: RevenueService.getRevenueName('net')
                },
                decimalsInFloat: 0,
                labels: {
                  formatter: value => FormatService.formatEuro(value)
                }
              },
              title: {
                text: 'Forecast: ' + RevenueService.getRevenueName('net')
              },
              stroke: {
                dashArray: dashedArray
              }
            });
            this.$refs.monthChartForecast2.updateSeries(res.data[1]);
            this.$refs.monthChartForecast2.updateOptions({
              yaxis: {
                min: min => {
                  if (res.data[1].some(items => items.data.some(x => x < 0))) {
                    return min;
                  } else {
                    return 0;
                  }
                },
                forceNiceScale: true,
                title: {
                  text: RevenueService.getRevenueName('db')
                },
                decimalsInFloat: 0,
                labels: {
                  formatter: value => FormatService.formatEuro(value)
                }
              },
              title: {
                text: 'Forecast: ' + RevenueService.getRevenueName('db')
              },
              stroke: {
                dashArray: dashedArray
              }
            });
            OfferService.getMonthlyRevenueOfYear('/offerValuePerCustomer', this.getOfferYearStart, this.getOfferYearEnd).then(res => {
              let valuePerCustomerArray1 = [];
              let valuePerCustomerArray2 = [];
              let posNettoArray = [];
              let dbArray = [];
              res.data.forEach(object => {
                posNettoArray.push(object.posNetto);
                dbArray.push(object.db);
                if (object.month == 12) {
                  valuePerCustomerArray1.push({
                    name: object.year,
                    data: posNettoArray
                  });
                  valuePerCustomerArray2.push({
                    name: object.year,
                    data: dbArray
                  });
                  posNettoArray = [];
                  dbArray = [];
                }
              });
              this.$refs.monthChartOfferValuePerCustomer1.updateSeries(valuePerCustomerArray1);
              this.$refs.monthChartOfferValuePerCustomer1.updateOptions({
                title: {
                  text: 'Auftragswert / Kunde: ' + RevenueService.getRevenueName('net')
                },
                yaxis: {
                  min: min => {
                    if (valuePerCustomerArray1.some(items => items.data.some(x => x < 0))) {
                      return min;
                    } else {
                      return 0;
                    }
                  },
                  forceNiceScale: true,
                  decimalsInFloat: 0,
                  title: {
                    text: RevenueService.getRevenueName('net')
                  },
                  labels: {
                    formatter: value => FormatService.formatEuro(value)
                  }
                }
              });
              this.$refs.monthChartOfferValuePerCustomer2.updateSeries(valuePerCustomerArray2);
              this.$refs.monthChartOfferValuePerCustomer2.updateOptions({
                yaxis: {
                  min: min => {
                    if (valuePerCustomerArray2.some(items => items.data.some(x => x < 0))) {
                      return min;
                    } else {
                      return 0;
                    }
                  },
                  forceNiceScale: true,
                  decimalsInFloat: 0,
                  title: {
                    text: RevenueService.getRevenueName('db')
                  },
                  labels: {
                    formatter: value => FormatService.formatEuro(value)
                  }
                },
                title: {
                  text: 'Auftragswert / Kunde: ' + RevenueService.getRevenueName('db')
                }
              });
              this.$store.dispatch('setLoading', false);
            });
          });
        });
      });
    }
  },
  computed: {
    ...mapGetters(['getOfferYearStart', 'getOfferYearEnd', 'getOfferYearItems', 'getLoading']),
    getYearStartAndGetYearEnd() {
      return `${this.getOfferYearStart}|${this.getOfferYearEnd}`;
    }
  },
  watch: {
    getYearStartAndGetYearEnd() {
      this.updateOrdersOfYearSpan();
    }
  }
};