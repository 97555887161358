import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('fiveYearGraph', {
    attrs: {
      "highestYearInFiveShownYears": _vm.highestYearInFiveShownYears,
      "fiveYearDataArray": _vm.fiveYearDataArray,
      "headLineText": _vm.headLineText,
      "entireCustomerInFiveYears": _vm.entireCustomerInFiveYears,
      "activeCustomer": _vm.activeCustomer,
      "inactiveCustomer": _vm.inactiveCustomer
    },
    on: {
      "increaseOneYearInFiveYearChart": _vm.increaseOneYearInFiveYearChart,
      "decreaseOneYearInFiveYearChart": _vm.decreaseOneYearInFiveYearChart,
      "showMonth": _vm.showMonth
    }
  }), _vm.currentYear != 0 ? _c('div', {
    attrs: {
      "fiveYearDataArray": _vm.fiveYearDataArray
    }
  }, [_c('monthGraph', {
    attrs: {
      "currentYear": _vm.currentYear,
      "monthlyData": _vm.newCustomerPerMonth,
      "entireCustomerData": _vm.entireCustomerData,
      "activeCustomer": _vm.activeCustomerOfMonth,
      "inactiveCustomer": _vm.inactiveCustomerOfMonth
    },
    on: {
      "increaseOneYear": _vm.increaseOneYear,
      "decreaseOneYear": _vm.decreaseOneYear,
      "showNewCustomers": _vm.showNewCustomers
    }
  })], 1) : _vm._e(), _vm.singeMonthClicked == true ? _c('div', [_c('customerDatatable', {
    attrs: {
      "items": _vm.newCustomerDatatableItems
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };