//import axios from 'axios'

export default {
  getListOfAllFibuKonten() {
    console.log()
    //return axios.get(``)
  },
  getListOfArticlesInFibuKonten(id) {
    console.log(id)
    //return axios.get(``)
  },
  getFiveYearDataOfFibuKonten(id, startyear) {
    console.log(id, startyear)
    //return axios.get(``)
  },
  getOneYearDataOfFibuKonten(id, month) {
    console.log(id, month)
    //return axios.get(``)
  }
}
