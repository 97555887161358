import "core-js/modules/es.array.push.js";
import articleTypesDevelopment from '@/services/statistics/articleTypesDevelopment.js';
import OpenLinkEyeComponent from '@/components/core/OpenLinkEyeComponent.vue';
import FormatService from '@/services/formatters/currencyFormatService.js';
import { monthNames } from '@/services/statistics/chartService';
const REVENUE_TYPES = ['net', 'db', 'netTotal', 'dbTotal'];
export default {
  components: {
    OpenLinkEyeComponent
  },
  data: function () {
    return {
      articleTypesSelected: [],
      articleCategorySelected: [],
      articleOfCategorySelected: [],
      articleTypesHeaders: [{
        text: 'Artikeltyp',
        value: 'name',
        align: 'start',
        sortable: true
      }],
      articleCategoryHeaders: [{
        text: 'Artikelkategorie',
        value: 'name',
        align: 'start',
        sortable: true
      }],
      articleOfCategoryHeaders: [{
        text: 'Artikel',
        value: 'name',
        align: 'start',
        sortable: true
      }, {
        text: 'Artikelnummer',
        value: 'artNr',
        align: 'start',
        sortable: true
      }, {
        value: 'symbol',
        align: 'end',
        sortable: false
      }],
      articleTypesObjectArray: [],
      articleTypesArray: [],
      articleTypesSearch: '',
      articleCategoryObjectArray: [],
      articleCategoryArray: [],
      articleCategorySearch: '',
      articleSearch: '',
      categorySearch: '',
      articleOfCategoryObjectArray: [],
      articleOfCategoryArray: [],
      articleOfCategorySearch: '',
      singleSelect: true,
      highestYearInFiveShownYearsOfArticleTypesOfArticleTypes: new Date().getFullYear(),
      highestYearInFiveShownYearsOfArticleTypesOfArticleCategory: new Date().getFullYear(),
      selectedYear: 0,
      selectedYearOfCategory: 0,
      // Five Year Chart //
      fiveYearChartsOfArticleTypesSeries: [],
      fiveYearChartOptionsOfArticleTypes: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.showMonth(this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes - 4 + config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [new Date().getFullYear() - 4, new Date().getFullYear() - 3, new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()]
        },
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        yaxis: [{
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
                return $1 + '.';
              }) + ' €';
            }
          }
        }]
      },
      ////////////////////
      // One Year Chart //
      oneYearChartsOfArticleTypesSeries: [],
      oneYearChartOptionsOfArticleTypes: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: monthNames
        },
        yaxis: [{
          /*
          data from api: 4349.4685
          data after format: 4.000 €
          */
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
                return $1 + '.';
              }) + ' €';
            }
          }
        }],
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        }
      },
      ////////////////////
      // Five Year Chart of Category//
      fiveYearChartsOfArticleCategorySeries: [],
      fiveYearChartOptionsOfArticleCategory: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.showMonthOfCategory(this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory - 4 + config.dataPointIndex);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [new Date().getFullYear() - 4, new Date().getFullYear() - 3, new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()]
        },
        yaxis: [{
          /*
          data from api: 4349.4685
          data after format: 4.000 €
          */
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
                return $1 + '.';
              }) + ' €';
            }
          }
        }],
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        }
      },
      ////////////////////
      ////////////////////
      // One Year Chart //
      oneYearChartsOfArticleCategorySeries: [],
      oneYearChartOptionsOfArticleCategory: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: monthNames
        },
        yaxis: [{
          /*
          data from api: 4349.4685
          data after format: 4.000 €
          */
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
                return $1 + '.';
              }) + ' €';
            }
          }
        }],
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        }
      }
      ////////////////////
    };
  },
  methods: {
    showArticleTypes() {
      articleTypesDevelopment.getListOfAllArticleTypes().then(response => {
        this.articleTypesObjectArray = response.data;
        for (let i = 0; i < this.articleTypesObjectArray.length; i++) {
          this.articleTypesArray.push({
            name: this.articleTypesObjectArray[i].tpBezeichung,
            firma: this.articleTypesObjectArray[i].firma,
            tpCode: this.articleTypesObjectArray[i].tpCode
          });
        }
      });
    },
    showArticleCategories() {
      articleTypesDevelopment.getListOfAllArticleCategories(this.articleTypesSelected[0].tpCode).then(response => {
        this.articleCategoryObjectArray = response.data;
        this.articleCategoryArray = [];
        for (let i = 0; i < this.articleCategoryObjectArray.length; i++) {
          this.articleCategoryArray.push({
            name: this.articleCategoryObjectArray[i].ktgBezeichnung,
            id: this.articleCategoryObjectArray[i].kategory
          });
        }
      });
    },
    showArticlesInCategory() {
      this.selectedYearOfCategory = 0;
      if (this.articleCategorySelected.length != 0) {
        articleTypesDevelopment.getListOfArticlesInCategorys(this.articleTypesSelected[0].tpCode, this.articleCategorySelected[0].id).then(response => {
          this.articleOfCategoryObjectArray = response.data;
          this.articleOfCategoryArray = [];
          for (let i = 0; i < this.articleOfCategoryObjectArray.length; i++) {
            this.articleOfCategoryArray.push({
              name: this.articleOfCategoryObjectArray[i].artBezeichung,
              artAutonr: this.articleOfCategoryObjectArray[i].artAutonr,
              artNr: this.articleOfCategoryObjectArray[i].artNr,
              symbol: {
                name: 'Artikeldetails',
                params: {
                  articleNumber: this.articleOfCategoryObjectArray[i].artNr
                }
              }
            });
          }
        });
        this.showFiveYearsOfArticleCategory();
      }
    },
    showArticlesWithoutCategory() {
      articleTypesDevelopment.getListOfArticlesOfType(this.articleTypesSelected[0].tpCode).then(response => {
        this.articleOfCategoryObjectArray = response.data;
        this.articleOfCategoryArray = [];
        for (let i = 0; i < this.articleOfCategoryObjectArray.length; i++) {
          this.articleOfCategoryArray.push({
            name: this.articleOfCategoryObjectArray[i].artBezeichung,
            artAutonr: this.articleOfCategoryObjectArray[i].artAutonr,
            artNr: this.articleOfCategoryObjectArray[i].artNr,
            symbol: {
              name: 'Artikeldetails',
              params: {
                articleNumber: this.articleOfCategoryObjectArray[i].artNr
              }
            }
          });
        }
      });
    },
    selectArticleType() {
      this.showArticlesWithoutCategory();
      this.showFiveYearsOfArticleTypes();
    },
    showFiveYearsOfArticleTypes() {
      let dbSum = [];
      let posNettoSum = [];
      let dbYear = [];
      let revenueYear = [];
      let responseDataArray = [];
      this.selectedYear = 0;
      articleTypesDevelopment.getFiveYearDataOfArticleTypes(this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes - 4, this.articleTypesSelected[0].tpCode).then(response => {
        responseDataArray = response.data;
        for (let i = 0; i < responseDataArray.length; i++) {
          dbSum.push(responseDataArray[i].dbSum);
          posNettoSum.push(responseDataArray[i].posNettoSum);
          revenueYear.push(responseDataArray[i].revenueYear);
          dbYear.push(responseDataArray[i].dbYear);
        }
        this.$refs.fiveYearChartsOfArticleTypes.updateOptions({
          xaxis: {
            categories: [this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes - 4, this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes - 3, this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes - 2, this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes - 1, this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes]
          }
        });
        this.$refs.fiveYearChartsOfArticleTypes.updateSeries([{
          name: 'Umsatz',
          data: revenueYear,
          type: 'line'
        }, {
          name: 'Deckungsbeitrag',
          data: dbYear,
          type: 'line'
        }, {
          name: 'Umsatz gesamt',
          data: posNettoSum,
          type: 'bar'
        }, {
          name: 'Deckungsbeitrag gesamt',
          data: dbSum,
          type: 'bar'
        }]);
      });
      this.showArticleCategories();
    },
    increaseFiveYears() {
      this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes--;
      this.showFiveYearsOfArticleTypes();
    },
    decreaseFiveYears() {
      if (this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes < new Date().getFullYear()) {
        this.highestYearInFiveShownYearsOfArticleTypesOfArticleTypes++;
        this.showFiveYearsOfArticleTypes();
      }
    },
    showMonth(year) {
      this.selectedYear = year;
      let dbSum = [];
      let posNettoSum = [];
      let dbYear = [];
      let revenueMonth = [];
      let responseDataArray = [];
      articleTypesDevelopment.getOneYearDataOfArticleTypes(year, this.articleTypesSelected[0].tpCode).then(response => {
        responseDataArray = response.data;
        for (let i = 0; i < responseDataArray.length; i++) {
          dbSum.push(responseDataArray[i].dbSum);
          posNettoSum.push(responseDataArray[i].posNettoSum);
          revenueMonth.push(responseDataArray[i].revenueMonth);
          dbYear.push(responseDataArray[i].dbMonth);
        }
        this.$refs.oneYearChartsOfArticleTypes.updateSeries([{
          name: 'Umsatz',
          data: revenueMonth,
          type: 'line'
        }, {
          name: 'Deckungsbeitrag',
          data: dbYear,
          type: 'line'
        }]);
      });
      this.showArticleCategories();
    },
    increaseOneYear() {
      this.selectedYear--;
      this.showMonth(this.selectedYear);
    },
    decreaseOneYear() {
      if (this.selectedYear < new Date().getFullYear()) {
        this.selectedYear++;
        this.showMonth(this.selectedYear);
      }
    },
    showFiveYearsOfArticleCategory() {
      let dbSum = [];
      let posNettoSum = [];
      let dbYear = [];
      let revenueYear = [];
      let responseDataArray = [];
      articleTypesDevelopment.getFiveYearDataOfArticleCategories(this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory - 4, this.articleTypesSelected[0].tpCode, this.articleCategorySelected[0].id).then(response => {
        responseDataArray = response.data;
        for (let i = 0; i < responseDataArray.length; i++) {
          dbSum.push(responseDataArray[i].dbSum);
          posNettoSum.push(responseDataArray[i].posNettoSum);
          revenueYear.push(responseDataArray[i].revenueYear);
          dbYear.push(responseDataArray[i].dbYear);
        }
        this.$refs.fiveYearChartsOfArticleCategory.updateOptions({
          xaxis: {
            categories: [this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory - 4, this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory - 3, this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory - 2, this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory - 1, this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory]
          }
        });
        this.$refs.fiveYearChartsOfArticleCategory.updateSeries([{
          name: 'Umsatz',
          data: revenueYear,
          type: 'line'
        }, {
          name: 'Deckungsbeitrag',
          data: dbYear,
          type: 'line'
        }, {
          name: 'Umsatz gesamt',
          data: posNettoSum,
          type: 'bar'
        }, {
          name: 'Deckungsbeitrag gesamt',
          data: dbSum,
          type: 'bar'
        }]);
      });
      this.showArticleCategories();
    },
    increaseFiveYearsOfCategory() {
      this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory--;
      this.showFiveYearsOfArticleCategory();
    },
    decreaseFiveYearsOfCategory() {
      if (this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory < new Date().getFullYear()) {
        this.highestYearInFiveShownYearsOfArticleTypesOfArticleCategory++;
        this.showFiveYearsOfArticleCategory();
      }
    },
    showMonthOfCategory(year) {
      this.selectedYearOfCategory = year;
      let dbSum = [];
      let posNettoSum = [];
      let dbYear = [];
      let revenueMonth = [];
      let responseDataArray = [];
      articleTypesDevelopment.getOneYearDataOfArticleTypes(year, this.articleTypesSelected[0].tpCode, this.articleCategorySelected[0].id).then(response => {
        responseDataArray = response.data;
        for (let i = 0; i < responseDataArray.length; i++) {
          dbSum.push(responseDataArray[i].dbSum);
          posNettoSum.push(responseDataArray[i].posNettoSum);
          revenueMonth.push(responseDataArray[i].revenueMonth);
          dbYear.push(responseDataArray[i].dbMonth);
        }
        this.$refs.oneYearChartsOfArticleCategory.updateSeries([{
          name: 'Umsatz',
          data: revenueMonth,
          type: 'line'
        }, {
          name: 'Deckungsbeitrag',
          data: dbYear,
          type: 'line'
        }]);
      });
    },
    increaseOneYearOfCategory() {
      this.selectedYearOfCategory--;
      this.showMonthOfCategory(this.selectedYearOfCategory);
    },
    decreaseOneYearOfCategory() {
      if (this.selectedYearOfCategory < new Date().getFullYear()) {
        this.selectedYearOfCategory++;
        this.showMonthOfCategory(this.selectedYearOfCategory);
      }
    }
  },
  beforeMount() {
    this.showArticleTypes();
  }
};