import axios from 'axios'

export default {
  getListOfAllAufwandsKonten() {
    return axios.get(`/v1/statistics/expense/groups`)
  },
  getListOfArticlesInAufwandskonten(id) {
    return axios.get(`/v1/statistics/expense/groups/${id}/article`)
  },
  getFiveYearDataOfAufwandskonten(id, startyear) {
    return axios.get(`/v1/statistics/expense/groups/${id}/years`, {
      params: { year: startyear }
    })
  },
  getOneYearDataOfAufwandskonten(id, month) {
    return axios.get(`/v1/statistics/expense/groups/${id}/months`, {
      params: { year: month }
    })
  }
}
