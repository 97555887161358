import axios from 'axios'

export default {
  getListOfAllArticleCodes() {
    return axios.get(`/v1/statistics/articles/codes`)
  },
  getListOfArticlesInCode(id) {
    return axios.get(`/v1/statistics/articles/codes/${id}/articles`)
  },
  getFiveYearDataOfStatisticCode(id, startyear) {
    return axios.get(`/v1/statistics/articles/codes/${id}/articles/years`, {
      params: { year: startyear }
    })
  },
  getOneYearDataOfStatisticCode(id, month) {
    return axios.get(`/v1/statistics/articles/codes/${id}/articles/months`, {
      params: { year: month }
    })
  }
}
