import "core-js/modules/es.array.push.js";
import statisticsCodesService from '@/services/statistics/statisticsCodesService.js';
import dataTable from '@/components/statistics/articles/dataTable.vue';
import articleTable from '@/components/statistics/articles/articleTable.vue';
import FiveYearGraph from './fiveYearGraph.vue';
import FormatService from '@/services/formatters/currencyFormatService.js';
import { monthNames } from '@/services/statistics/chartService.js';
const REVENUE_TYPES = ['net', 'db', 'netTotal', 'dbTotal'];
export default {
  components: {
    dataTable,
    articleTable,
    FiveYearGraph
  },
  data: function () {
    return {
      tabName: 'Statistikcode',
      headLineText: 'Entwicklung der Statistikcodes',
      dataTableKey: 'codes',
      articlesInStatisticCodesSearch: '',
      statisticCodeSelected: [],
      //!
      statisticCodesObjectArray: [],
      statisticCodesArray: [],
      singleSelect: true,
      highestYearInFiveShownYearsOfSatisticCodes: new Date().getFullYear(),
      selectedYear: 0,
      dbYear: [],
      revenueYear: [],
      dbSum: [],
      posNettoSum: [],
      statisticCodeHeaders: [{
        text: 'Statistikcode',
        value: 'codes',
        align: 'start',
        sortable: true
      }],
      articlesInStatisticCodesHeaders: [{
        text: 'Artikel',
        value: 'articlename',
        align: 'start',
        sortable: true
      }, {
        text: 'Artikelnummer',
        value: 'artNr',
        align: 'start',
        sortable: true
      }],
      articlesInStatisticCodesArray: [],
      articlesInStatisticCodesObjectArray: [],
      ///////////////////////////
      // Five Year Chart //
      fiveYearChartsOfStatisticCodesSeries: [],
      fiveYearChartOptionsOfStatisticCodes: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.showMonth(this.highestYearInFiveShownYearsOfSatisticCodes - 4 + config.dataPointIndex);
            }
          },
          markers: {
            enabled: true,
            size: 5
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [new Date().getFullYear() - 4, new Date().getFullYear() - 3, new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()]
        },
        yaxis: [{
          /*
          data from api: 4349.4685
          data after format: 4.000 €
          */
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
                return $1 + '.';
              }) + ' €';
            }
          }
        }],
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        }
      },
      ////////////////////
      // One Year Chart //
      oneYearChartsOfStatisticCodesSeries: [],
      oneYearChartOptionsOfStatisticCodes: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: monthNames
        },
        yaxis: [{
          /*
          data from api: 4349.4685
          data after format: 4.000 €
          */
          decimalsInFloat: 0,
          labels: {
            formatter: function (value) {
              return ('' + value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function ($1) {
                return $1 + '.';
              }) + ' €';
            }
          }
        }],
        tooltip: {
          shared: true,
          y: {
            formatter: function (value, {
              series,
              seriesIndex,
              dataPointIndex
            }) {
              if (REVENUE_TYPES[seriesIndex] == 'db') {
                let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
                return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
              } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
                return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
              } else {
                return FormatService.formatEuro(value);
              }
            }
          }
        }
      }
      //////////////////////////
    };
  },
  methods: {
    showStatisticCodes() {
      statisticsCodesService.getListOfAllArticleCodes().then(response => {
        this.statisticCodesObjectArray = response.data;
        this.statisticCodesObjectArray.forEach(element => {
          this.statisticCodesArray.push({
            codes: element.code
          });
        });
      });
    },
    showArticlesInStatisticCodes(selected) {
      this.statisticCodeSelected = selected;
      this.selectedYear = 0;
      console.log(this.statisticCodeSelected[0].codes);
      statisticsCodesService.getListOfArticlesInCode(this.statisticCodeSelected[0].codes).then(response => {
        this.articlesInStatisticCodesObjectArray = response.data;
        this.articlesInStatisticCodesArray = [];
        this.articlesInStatisticCodesObjectArray.forEach(element => {
          this.articlesInStatisticCodesArray.push({
            articlename: element.artBezeichung,
            artNr: element.artNr
          });
        });
      });
      this.showFiveYearsOfArticleTypes();
    },
    showFiveYearsOfArticleTypes() {
      this.dbSum = [];
      this.posNettoSum = [];
      this.dbYear = [];
      this.revenueYear = [];
      let responseDataArray = [];
      statisticsCodesService.getFiveYearDataOfStatisticCode(this.statisticCodeSelected[0].codes, this.highestYearInFiveShownYearsOfSatisticCodes - 4).then(response => {
        responseDataArray = response.data;
        responseDataArray.forEach(element => {
          //console.warn(element)
          this.dbSum.push(element.dbSum);
          this.posNettoSum.push(element.posNettoSum);
          this.revenueYear.push(element.revenueYear);
          this.dbYear.push(element.dbYear);
        });
      });
    },
    showMonth(year) {
      console.log(year);
      this.selectedYear = year;
      let dbSum = [];
      let posNettoSum = [];
      let dbYear = [];
      let revenueMonth = [];
      let responseDataArray = [];
      statisticsCodesService.getOneYearDataOfStatisticCode(this.statisticCodeSelected[0].codes, year).then(response => {
        responseDataArray = response.data;
        responseDataArray.forEach(element => {
          dbSum.push(element.dbSum);
          posNettoSum.push(element.posNettoSum);
          revenueMonth.push(element.revenueMonth);
          dbYear.push(element.dbMonth);
        });
        this.$refs.oneYearChartsOfStatisticCodes.updateSeries([{
          name: 'Umsatz',
          data: revenueMonth,
          type: 'line'
        }, {
          name: 'Deckungsbeitrag',
          data: dbYear,
          type: 'line'
        }]);
      });
    },
    increaseOneYear() {
      this.selectedYear--;
      this.showMonth(this.selectedYear);
    },
    decreaseOneYear() {
      if (this.selectedYear < new Date().getFullYear()) {
        this.selectedYear++;
        this.showMonth(this.selectedYear);
      }
    },
    increaseFiveYears() {
      this.highestYearInFiveShownYearsOfSatisticCodes--;
      this.showFiveYearsOfArticleTypes();
    },
    decreaseFiveYears() {
      if (this.highestYearInFiveShownYearsOfSatisticCodes < new Date().getFullYear()) {
        this.highestYearInFiveShownYearsOfSatisticCodes++;
        this.showFiveYearsOfArticleTypes();
      }
    }
  },
  beforeMount() {
    this.showStatisticCodes();
  }
};