import "core-js/modules/es.array.push.js";
import OfferService from '@/services/statistics/offerService.js';
import MonthChart from '@/components/statistics/Revenue/MonthChart.vue';
import { mapGetters } from 'vuex';
import MixinMethodObject from '@/components/statistics/offer/IncomingOrders.vue';
import FormatService from '@/services/formatters/currencyFormatService.js';
const REVENUE_TYPES = ['net', 'db'];
export default {
  components: {
    MonthChart
  },
  mixins: [MixinMethodObject],
  data: () => ({
    yearOfferObjectArray: [],
    yearlyChartOptions: {
      title: {
        align: 'left',
        style: {
          fontSize: '20px'
        }
      },
      chart: {
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        enabled: true,
        size: 5
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        title: {
          text: 'Jahr'
        }
      },
      yaxis: [{
        title: {
          text: 'Anzahl'
        },
        decimalsInFloat: 0,
        labels: {
          formatter: value => FormatService.formatEuro(value)
        }
      }],
      tooltip: {
        shared: true,
        y: {
          formatter: function (value, {
            series,
            seriesIndex,
            dataPointIndex
          }) {
            if (REVENUE_TYPES[seriesIndex] == 'db') {
              let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
              return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
            } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
              return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
            } else {
              return FormatService.formatEuro(value);
            }
          }
        }
      },
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      }
    }
  }),
  methods: {
    updateOrdersOfYearSpan() {
      let yearYearObjectArray = this.yearYearObjectArray = [];
      OfferService.getOffersForYearSpan('/offerValuePerCustomer', this.getOfferYearStart, this.getOfferYearEnd).then(res => {
        for (let year = this.getOfferYearStart; year <= this.getOfferYearEnd; year++) {
          yearYearObjectArray.push(year);
        }
        let avgOrderDBCustomer = [];
        let roundAvgOrderDBCustomer = [];
        let totalAverageOrderValueCustomer = [];
        let roundTotalAverageOrderValueCustomer = [];
        res.data.forEach(offerObject => {
          avgOrderDBCustomer.push(offerObject.avgOrderDBCustomer);
          totalAverageOrderValueCustomer.push(offerObject.totalAverageOrderValueCustomer);
        });
        avgOrderDBCustomer.forEach(element => {
          roundAvgOrderDBCustomer.push(Math.round(element * 100) / 100);
        });
        totalAverageOrderValueCustomer.forEach(element => {
          roundTotalAverageOrderValueCustomer.push(Math.round(element * 100) / 100);
        });
        this.$refs.yearChart.updateSeries([{
          name: '⌀ Umsatz / Kunde',
          data: roundTotalAverageOrderValueCustomer,
          type: 'line'
        }, {
          name: '⌀ Deckungsbeitrag / Kunde',
          data: roundAvgOrderDBCustomer,
          type: 'line'
        }]);
        this.$refs.yearChart.updateOptions({
          title: {
            text: 'Jahresansicht ' + this.getOfferYearStart + ' - ' + this.getOfferYearEnd
          },
          xaxis: {
            categories: yearYearObjectArray
          }
        });
      });
    }
  },
  computed: {
    ...mapGetters(['getOfferYearStart', 'getOfferYearEnd', 'getOfferYearItems']),
    getYearStartAndGetYearEnd() {
      return `${this.getOfferYearStart}|${this.getOfferYearEnd}`;
    }
  },
  watch: {
    getYearStartAndGetYearEnd() {
      this.updateOrdersOfYearSpan();
    }
  }
};