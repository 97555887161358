import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, [_c('div', {
    staticClass: "mx-auto my-12 v-card v-sheet theme--light"
  }, [_c(VTabs, {
    attrs: {
      "grow": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c(VTab, {
    attrs: {
      "href": "#articletypes"
    }
  }, [_vm._v("Artikeltypen")]), _c(VTab, {
    attrs: {
      "href": "#revenuegroups"
    }
  }, [_vm._v("Erlösgruppen")]), _c(VTab, {
    attrs: {
      "href": "#statisticgroups"
    }
  }, [_vm._v("Statistikgruppen")]), _c(VTab, {
    attrs: {
      "href": "#statisticcodes"
    }
  }, [_vm._v("Statistikcode")]), _c(VTab, {
    attrs: {
      "href": "#expenseaccounts"
    }
  }, [_vm._v("Aufwandskonten")]), _c(VTab, {
    attrs: {
      "href": "#financebookaccounts"
    }
  }, [_vm._v("Fibukonten")])], 1), _c(VTabsItems, {
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c(VTabItem, {
    attrs: {
      "value": "articletypes"
    }
  }, [_c('articleTypes')], 1), _c(VTabItem, {
    attrs: {
      "value": "statisticgroups"
    }
  }, [_c('statisticGroups')], 1), _c(VTabItem, {
    attrs: {
      "value": "statisticcodes"
    }
  }, [_c('statisticCodes')], 1), _c(VTabItem, {
    attrs: {
      "value": "expenseaccounts"
    }
  }, [_c('aufwandskonten')], 1), _c(VTabItem, {
    attrs: {
      "value": "financebookaccounts"
    }
  }, [_c('fibukonten')], 1)], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };