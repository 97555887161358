import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "disabled"
  }, [_c('p', {
    staticClass: "d-flex justify-center"
  }, [_c(VCard, {
    staticClass: "mt-10 mb-10 pa-5 text-center red--text text--lighten-1",
    staticStyle: {
      "width": "50vw"
    },
    attrs: {
      "elevation": "2"
    }
  }, [_vm._v(" Fibukonten sind noch nicht vollständig in der Datenbank implementiert ")])], 1), _c('div', {
    staticClass: "d-none"
  }, [_c(VCardTitle, [_vm._v(" Fibukonto auswählen "), _c(VSpacer), _c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "single-line": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.fibuKontenSearch,
      callback: function ($$v) {
        _vm.fibuKontenSearch = $$v;
      },
      expression: "fibuKontenSearch"
    }
  })], 1), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.fibuKontenHeaders,
      "items": _vm.fibuKontenArray,
      "single-select": _vm.singleSelect,
      "search": _vm.fibuKontenSearch,
      "items-per-page": 10,
      "item-key": "group",
      "show-select": ""
    },
    on: {
      "input": _vm.showArticlesInFibuKonten
    },
    model: {
      value: _vm.fibuKontenSelected,
      callback: function ($$v) {
        _vm.fibuKontenSelected = $$v;
      },
      expression: "fibuKontenSelected"
    }
  }), this.fibuKontenSelected.length != 0 ? [_c(VCardTitle, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "single-line": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.articlesInfibuKontenSearch,
      callback: function ($$v) {
        _vm.articlesInfibuKontenSearch = $$v;
      },
      expression: "articlesInfibuKontenSearch"
    }
  })], 1), _c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.articlesInfibuKontenHeaders,
      "items": _vm.articlesInfibuKontenArray,
      "search": _vm.articlesInfibuKontenSearch,
      "items-per-page": 10,
      "item-key": "articlename"
    }
  })] : _vm._e(), this.fibuKontenSelected.length != 0 ? _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v(" Entwicklung der Fibukonten "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.highestYearInFiveShownYearsOfFibuKonten - 4) + "-" + _vm._s(this.highestYearInFiveShownYearsOfFibuKonten))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseFiveYears
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseFiveYears
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('apexchart', {
    ref: "fiveYearChartsOfFibuKonten",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.fiveYearChartOptionsOfFibuKonten,
      "series": _vm.fiveYearChartsOfFibuKontenSeries
    }
  })], 1) : _vm._e(), _vm.selectedYear != 0 ? _c('div', [_c('div', {
    staticClass: "header"
  }, [_c('h2', [_vm._v(" Monatsansicht "), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(this.selectedYear))])]), _c(VCol, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.increaseOneYear
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.decreaseOneYear
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1)], 1), _c('apexchart', {
    ref: "oneYearChartsOfFibuKonten",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.oneYearChartOptionsOfFibuKonten,
      "series": _vm.oneYearChartsOfFibuKontenSeries
    }
  })], 1) : _vm._e()], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };