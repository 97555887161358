import totalDevelopment from '@/components/statistics/customerDevelopment/totalDevelopment.vue';
import storeDevelopment from '@/components/statistics/customerDevelopment/storeDevelopment.vue';
import customersGroups from '@/components/statistics/customerDevelopment/customersGroups.vue';
import addressTypes from '@/components/statistics/customerDevelopment/addressTypes.vue';
export default {
  components: {
    totalDevelopment,
    storeDevelopment,
    customersGroups,
    addressTypes
  },
  data: () => ({
    currentTab: ''
  }),
  watch: {
    currentTab() {
      const tab = this.currentTab;
      if (this.$route.query.tab != tab) this.$router.replace({
        query: {
          ...this.$route.query,
          tab
        }
      });
    }
  },
  mounted() {
    this.currentTab = this.$route.query.tab;
  }
};