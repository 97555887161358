import axios from 'axios'

export default {
  getListOfAllArticleCategories(tp_code) {
    return axios.get(`/v1/statistics/articles/types/${tp_code}/categories`)
  },
  getListOfArticlesInCategorys(tp_code, category_id) {
    return axios.get(
      `/v1/statistics/articles/types/${tp_code}/categories/${category_id}/article`
    )
  },
  getListOfArticlesOfType(tp_code) {
    return axios.get(`/v1/statistics/articles/types/${tp_code}/articles`)
  },
  getListOfAllArticleTypes() {
    return axios.get(`/v1/statistics/articles/types`)
  },
  getFiveYearDataOfArticleTypes(startyear, tp_code) {
    return axios.get(
      `/v1/statistics/articles/types/${tp_code}/articles/years`,
      {
        params: { year: startyear }
      }
    )
  },
  getOneYearDataOfArticleTypes(startyear, tp_code) {
    return axios.get(
      `/v1/statistics/articles/types/${tp_code}/articles/months`,
      {
        params: { year: startyear }
      }
    )
  },
  getOneYearDataOfArticleCategories(startyear, tp_code, category_id) {
    return axios.get(
      `/v1/statistics/articles/types/${tp_code}/categories/${category_id}/articles/months`,
      {
        params: { year: startyear }
      }
    )
  },
  getFiveYearDataOfArticleCategories(startyear, tp_code, category_id) {
    return axios.get(
      `/v1/statistics/articles/types/${tp_code}/categories/${category_id}/articles/years`,
      {
        params: { year: startyear }
      }
    )
  }
}
