import "core-js/modules/es.array.push.js";
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js';
import monthGraph from '@/components/statistics/customerDevelopment/monthGraph.vue';
import fiveYearGraph from '@/components/statistics/customerDevelopment/fiveYearGraph.vue';
import addressService from '@/services/crm/addressService.js';
import customerDatatable from '@/components/statistics/customerDevelopment/customerDatatable.vue';
export default {
  components: {
    monthGraph,
    fiveYearGraph,
    customerDatatable
  },
  data: function () {
    return {
      address: {
        name: '',
        street: '',
        postcode: '',
        city: '',
        country: '',
        tel: '',
        email: '',
        addressTypes: [{
          key: {
            firma: 1,
            lfdnr: 26
          },
          bezeichnung: 'Konsument',
          fldDisplayBez: 'Konsument'
        }, {
          key: {
            firma: 1,
            lfdnr: 27
          },
          bezeichnung: 'Patient',
          fldDisplayBez: 'Patient'
        }]
      },
      currentYear: 0,
      singeMonthClicked: false,
      highestYearInFiveShownYears: new Date().getFullYear(),
      responseDataArray: [],
      fiveYearDataArray: [],
      entireCustomerInFiveYears: [],
      activeCustomer: [],
      inactiveCustomer: [],
      entireCustomerData: [],
      activeCustomerOfMonth: [],
      inactiveCustomerOfMonth: [],
      newCustomerPerMonth: [],
      entireCustomerInMonth: [],
      newCustomerDataTable: [],
      headLineText: 'Gesamtentwicklung der Kunden',
      newCustomerDatatableItems: [],
      newCustomersHeader: [{
        text: 'Name',
        value: 'name',
        align: 'start',
        sortable: true
      }, {
        text: 'Telefonnummer',
        value: 'number',
        align: 'start',
        sortable: true
      }, {
        text: 'Kundennummer',
        value: 'kdnr',
        align: 'start',
        sortable: true
      }, {
        text: '',
        value: 'symbol',
        align: 'end',
        sortable: false
      }]
    };
  },
  methods: {
    showFiveYears() {
      this.fiveYearDataArray = [];
      this.entireCustomerInFiveYears = [];
      this.activeCustomer = [];
      this.inactiveCustomer = [];
      customerDevelopmentService.getTotalCustomerDevelopmentOfFiveYear(this.highestYearInFiveShownYears - 4).then(response => {
        response.data.forEach(year => {
          this.fiveYearDataArray.push(year.customerNumber);
          this.entireCustomerInFiveYears.push(year.totalCustomer);
          this.activeCustomer.push(year.activeCustomer);
          this.inactiveCustomer.push(year.inactiveCustomer);
        });
      });
    },
    increaseOneYear() {
      this.currentYear--;
      this.showMonth(this.currentYear);
    },
    decreaseOneYear() {
      if (this.currentYear < new Date().getFullYear()) {
        this.currentYear++;
        this.showMonth(this.currentYear);
      }
    },
    increaseOneYearInFiveYearChart() {
      this.highestYearInFiveShownYears--;
      this.showFiveYears();
    },
    decreaseOneYearInFiveYearChart() {
      if (this.highestYearInFiveShownYears < new Date().getFullYear()) {
        this.highestYearInFiveShownYears++;
        this.showFiveYears();
      }
    },
    showMonth(year) {
      //TODO: Diese Methode wird nicht aufgerufen

      this.currentYear = year;
      this.newCustomerPerMonth = [];
      this.entireCustomerData = [];
      this.activeCustomerOfMonth = [];
      this.inactiveCustomerOfMonth = [];

      // Clear Datatable if no month is specified
      this.newCustomerDatatableItems = [];
      customerDevelopmentService.getTotalCustomerDevelopmentOfYear(this.currentYear, this.selectedGroup).then(response => {
        response.data.forEach(month => {
          this.newCustomerPerMonth.push(month.customerNumber);
          this.entireCustomerData.push(month.totalCustomer);
          this.activeCustomerOfMonth.push(month.activeCustomer);
          this.inactiveCustomerOfMonth.push(month.inactiveCustomer);
        });
      });
    },
    showNewCustomers(index) {
      this.singeMonthClicked = true;
      this.newCustomerDatatableItems = [];
      customerDevelopmentService.getListOfNewCustomers(this.currentYear, index + 1).then(response => {
        this.newCustomerDatatableItems = response.data;
      });
    },
    addressTypesIcons(item) {
      return addressService.getAddressTypeDetailed(item).icon;
    },
    addressTypesNames(item) {
      //this.adressTypeName.push(addressService.getAddressTypeDetailed(item).name)
      return addressService.getAddressTypeDetailed(item).name;
    }
  },
  beforeMount() {
    this.showFiveYears();
  }
};