import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mx-4 my-4"
  }, [_c('div', {
    staticClass: "d-flex mx-4"
  }, [_c('span', {
    staticClass: "text-h6 font-weight-light d-inline-flex"
  }, [_c(VSelect, {
    staticClass: "me-3 fit",
    attrs: {
      "hint": "Anfangsjahr",
      "persistent-hint": "",
      "items": _vm.getOfferYearItems
    },
    on: {
      "change": selected => _vm.yearStartSelectionChange(selected)
    },
    model: {
      value: _vm.getOfferYearStart,
      callback: function ($$v) {
        _vm.getOfferYearStart = $$v;
      },
      expression: "getOfferYearStart"
    }
  }), _c('span', {
    staticClass: "mt-4 text-center"
  }, [_vm._v("-")]), _c(VSelect, {
    staticClass: "mx-3 fit",
    attrs: {
      "hint": "Endjahr",
      "persistent-hint": "",
      "items": _vm.getOfferYearItems
    },
    on: {
      "change": selected => _vm.yearEndSelectionChange(selected)
    },
    model: {
      value: _vm.getOfferYearEnd,
      callback: function ($$v) {
        _vm.getOfferYearEnd = $$v;
      },
      expression: "getOfferYearEnd"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearBack
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-left-thick")])], 1), _c(VBtn, {
    attrs: {
      "icon": "",
      "color": "black"
    },
    on: {
      "click": _vm.yearForward
    }
  }, [_c(VIcon, [_vm._v("mdi-arrow-right-thick")])], 1)], 1), _c('apexchart', {
    ref: "yearChart",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.yearlyChartOptions,
      "series": _vm.yearOfferObjectArray
    },
    on: {
      "click": _vm.showMonth
    }
  }), this.selectedYear != -1 ? _c('div', [_c(VRow, {
    staticClass: "d-flex justify-end"
  }, [_c(VTextField, {
    staticClass: "shrink mb-4 mx-4",
    staticStyle: {
      "max-width": "250px"
    },
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "single-line": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VRow, [_c(VCol, {
    staticClass: "col-md-4"
  }, [_c(VDataTable, {
    staticClass: "elevation-1",
    attrs: {
      "dense": "",
      "show-select": "",
      "single-select": true,
      "headers": _vm.forecastHeaders,
      "items": _vm.forecastItems,
      "item-key": "index",
      "items-per-page": 10,
      "loading": _vm.loadingList
    },
    scopedSlots: _vm._u([{
      key: "item.showOffer",
      fn: function ({
        item
      }) {
        return [_c('OpenLinkEyeComponent', {
          attrs: {
            "destination": {
              name: 'crmAddressDocumentDetailed',
              params: {
                lfdnr: item.lfdnr,
                wf_cid: item.wf_cid
              }
            },
            "newTab": true
          }
        })];
      }
    }], null, false, 2092825674),
    model: {
      value: _vm.selectedOffer,
      callback: function ($$v) {
        _vm.selectedOffer = $$v;
      },
      expression: "selectedOffer"
    }
  })], 1), _c(VCol, [_c(VDataTable, {
    staticClass: "elevation-4 py-2 px-2",
    attrs: {
      "headers": _vm.articleListHeader,
      "items": _vm.articleListArray,
      "items-per-page": 10,
      "search": _vm.search,
      "item-key": "nr",
      "sort-by": "nr",
      "no-data-text": "Bitte wählen Sie einen Auftrag aus"
    },
    scopedSlots: _vm._u([{
      key: "item.showArticle",
      fn: function ({
        item
      }) {
        return [_c('OpenLinkEyeComponent', {
          attrs: {
            "destination": {
              name: 'Artikeldetails',
              params: {
                articleNumber: item.nr
              }
            },
            "newTab": true
          }
        })];
      }
    }], null, false, 663533947)
  })], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };