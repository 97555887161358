import "core-js/modules/es.array.push.js";
import OfferService from '@/services/statistics/offerService.js';
import FormatService from '@/services/formatters/currencyFormatService.js';
import MixinMethodObject from '@/components/statistics/offer/IncomingOrders.vue';
import { mapGetters } from 'vuex';
import OpenLinkEyeComponent from '@/components/core/OpenLinkEyeComponent.vue';
const REVENUE_TYPES = ['net', 'db'];
export default {
  components: {
    OpenLinkEyeComponent
  },
  mixins: [MixinMethodObject],
  data: () => ({
    selectedOffer: [],
    loadingList: true,
    selectedYear: -1,
    yearOfferObjectArray: [],
    articleListArray: [],
    forecastItems: [],
    articleListHeader: [{
      text: 'Name',
      value: 'name',
      align: 'start',
      sortable: true
    }, {
      text: 'Artikelnummer',
      value: 'nr',
      align: 'start',
      sortable: true
    }, {
      text: 'Artikel anzeigen',
      value: 'showArticle',
      sortable: false,
      align: 'center',
      width: '12em'
    }],
    search: '',
    forecastHeaders: [{}, {
      text: 'Auftragsnummer',
      value: 'nr',
      align: 'start',
      sortable: true
    }, {
      text: 'Status',
      value: 'status',
      align: 'start',
      sortable: true
    }, {
      text: 'Auftrag anzeigen',
      value: 'showOffer',
      sortable: false,
      align: 'center',
      width: '12em'
    }],
    yearlyChartOptions: {
      title: {
        align: 'left',
        style: {
          fontSize: '20px'
        }
      },
      chart: {
        zoom: {
          enabled: false
        }
      },
      markers: {
        enabled: true,
        size: 5
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      stroke: {
        dashArray: [5, 5]
      },
      yaxis: [{
        decimalsInFloat: 0,
        lables: {
          formatter: value => FormatService.formatEuro(value)
        }
      }],
      tooltip: {
        shared: true,
        y: {
          formatter: function (value, {
            series,
            seriesIndex,
            dataPointIndex
          }) {
            if (REVENUE_TYPES[seriesIndex] == 'db') {
              let pvalue = parseFloat((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100);
              return FormatService.formatEuro(value) + ' (' + (isFinite(pvalue) ? pvalue : 0).toFixed(2) + '%)';
            } else if (REVENUE_TYPES[seriesIndex] == 'dbTotal') {
              return FormatService.formatEuro(value) + ' (' + ((series[seriesIndex][dataPointIndex] / series[seriesIndex - 1][dataPointIndex] || 0) * 100).toFixed(2) + '%)';
            } else {
              return FormatService.formatEuro(value);
            }
          }
        }
      },
      xaxis: {
        title: {
          text: 'Jahr'
        }
      }
    }
  }),
  methods: {
    updateOrdersOfYearSpan() {
      let yearYearObjectArray = this.yearYearObjectArray = [];
      OfferService.getOffersForYearSpan('/forecast', this.getOfferYearStart, this.getOfferYearEnd).then(res => {
        for (let year = this.getOfferYearStart; year <= this.getOfferYearEnd; year++) {
          yearYearObjectArray.push(year);
        }
        let total = [];
        let deckungsbeitrag = [];
        res.data.forEach(offerObject => {
          total.push(offerObject.posNetto);
          deckungsbeitrag.push(offerObject.db);
        });
        this.$refs.yearChart.updateSeries([{
          name: 'Gesamt',
          data: total
        }, {
          name: 'Deckungsbeitrag',
          data: deckungsbeitrag
        }]);
        this.$refs.yearChart.updateOptions({
          title: {
            text: 'Jahresansicht ' + this.getOfferYearStart + ' - ' + this.getOfferYearEnd
          },
          xaxis: {
            categories: yearYearObjectArray
          }
        });
      });
    },
    showMonth(event, chartContext, config) {
      this.loadingList = true;
      let selected = this.getOfferYearStart + config.dataPointIndex;
      this.selectedYear = selected;
      this.forecastItems = [];
      OfferService.getForecastOfferForYear(selected).then(res => {
        res.data.forEach((element, index) => {
          this.forecastItems.push({
            datum: element.datum.split(' ')[0],
            nr: element.shortType + ' ' + element.nr,
            id: element.nr,
            status: element.state,
            wf_cid: element.wf_cid,
            lfdnr: element.lfdnr,
            index: index
          });
        });
        this.loadingList = false;
      });
    },
    showArticleList(selectedElement) {
      this.articleListArray = [];
      OfferService.getForecastArticlesForYear([selectedElement[0].id]).then(res => {
        res.data.forEach(element => {
          this.articleListArray.push({
            name: element.artName,
            nr: element.artNr
          });
        });
      });
    }
  },
  computed: {
    ...mapGetters(['getOfferYearStart', 'getOfferYearEnd', 'getOfferYearItems']),
    getYearStartAndGetYearEnd() {
      return `${this.getOfferYearStart}|${this.getOfferYearEnd}`;
    }
  },
  watch: {
    getYearStartAndGetYearEnd() {
      this.updateOrdersOfYearSpan();
    },
    selectedOffer() {
      this.showArticleList(this.selectedOffer);
    }
  }
};